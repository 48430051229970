<template functionnal>
  <section class="section">
    <div class="template pb-4">
      <div class="template__content mb-2">
        <h1>Notre Histoire</h1>
        <br />
        En 1997, Femmes Initiatives voit le jour dans le quartier Bédier-Boutroux. Fondée par des
        femmes du 13è diplômées et issues de l’immigration, l’association vise à
        <strong
          >promouvoir les échanges et les initiatives dans les domaines de l&#39;économie, du social
          et du culturel</strong
        >.
      </div>
    </div>

    <div class="hero hero-img parallax-img firstimg">
      <div class="hero-body"></div>
    </div>

    <div class="template px-4 my-8">
      <div class="template__content">
        Son objectif :
        <strong class="marker-effect marker-effect--bold">
          apporter une réponse aux difficultés que peuvent rencontrer les personnes issues de
          l’immigration</strong
        >, largement représentées dans le 13è , pour accéder à l’emploi et s’inscrire durablement
        dans l’espace social en France. Au début des années 2000, Femmes Initiatives est une des
        rares associations de proximité implantées dans le quartier. <br /><br />
        Pour pallier ce manque, l’association décide d’intervenir dans plusieurs champs d’action :
        <strong class="marker-effect marker-effect--bold"
          >celui de l’accompagnement à l’insertion professionnelle</strong
        >
        d’un public confronté à de nombreux freins tels la maîtrise de la langue française ou la
        connaissance de l’outil informatique, celui de la médiation sociale et juridique, et enfin,
        celui du développement local notamment avec la création d’un café associatif «Au bao chene»
        qu’elle a porté pendant plusieurs années. <br /><br />
        Grâce à ces actions, de nombreux habitants du quartier ont pu bénéficier d’emplois et de
        stages au sein de la structure, qui sont des tremplins dans leur insertion professionnelle.
        Au fil des années,
        <strong class="marker-effect marker-effect--bold"
          >les actions menées portent leur fruit</strong
        >. Le quartier se dote de nouveaux partenaires comme le Point d’Accès au Droit, et
        l’association se recentre sur l’insertion sociale et professionnelle, la formation
        linguistique et numérique et le vivre ensemble à travers des activités culturelles. <br />
      </div>
    </div>

    <div class="hero hero-img parallax-img secondimg">
      <div class="hero-body"></div>
    </div>

    <div class="template px-4 my-8">
      <div class="template__content">
        L’installation au 7, rue Lachelier en 2003 et l’investissement important des bénévoles,
        permet l’accueil d’un public plus large et plus nombreux, une offre de cours de français
        plus étoffée et une diversification des activités. <br /><br />

        C’est dans cette perspective qu’en 2016,<strong class="marker-effect marker-effect--bold">
          des cours de français destinés à un public de demandeurs d’asile/réfugiés faiblement
          scolarisé ont été mis en place
        </strong>
        et que depuis 2020, des ateliers numériques pour viser l’autonomie dans les démarches de la
        vie quotidienne et de recherche d’emploi sont proposés toujours dans un même objectif :
        <strong class="marker-effect marker-effect--bold"
          >favoriser l’insertion socioprofessionnelle des parisiens issus de l’immigration.</strong
        >
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.template .template__content {
  min-height: initial;
}
.firstimg {
  height: 500px;
  background: url("../assets/images/Notre-histoire.jpg") no-repeat center center fixed;
  background-size: 100%;
}
.secondimg {
  height: 500px;
  background: url("../assets/images/00 - Notre histoire photo 2.jpg") no-repeat center center fixed;
  background-size: 100%;
}

@media only screen and (max-width: 800px) {
  .firstimg,
  .secondimg {
    height: 200px;
    background-size: 150%;
  }
}
</style>
